import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../../auth/auth.service';
import { ChartOptions } from '../services/estates-chart.service';
import { SeriesLabel } from '../services/estates.service';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class LongtermPieEndChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();

    constructor(private authService: AuthService, private translateService: TranslateService) {
        this.translateService.stream(['estates.calculation.chart.profit', 'estates.calculation.chart.expenditure']).subscribe((keys) => {
            const dataChart = this.dataChart.value;
            if (dataChart?.labels?.length) {
                dataChart.labels[0] = keys['estates.calculation.chart.profit'];
                dataChart.labels[1] = keys['estates.calculation.chart.expenditure'];
                this.dataChart.next(dataChart);
            }
        });
        this.height = window.innerHeight - window.innerHeight / 2.5;

        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            labels: ['Příjmy', 'Výdaje'],
            chart: {
                type: 'donut',
                width: '100%',
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 150px, 150px auto; ',
            },
            colors: ['#E84E0E', '#1390e1'],
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: '70%',
                    },
                },
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    formatter: (value: any) => (value - 1).toString(),
                },
                y: {
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            dataLabels: {
                enabled: true,
            },
        } as any);
    }

    updateSeries(newValue: SeriesLabel): void {
        this.logo = this.authService.getLogoPath();

        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = { ...currentObject };
            updatedObject.series = newValue.series as any;
            if (newValue.colors) {
                updatedObject.colors = newValue.colors;
            }

            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 150px, 150px auto; ';
            }
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}
