import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CreateFinProbabilityRequest, CreateHistoryDataRequest} from 'kfp';
import {ChartOptions} from './historical-chart.service';
import moment from 'moment/moment';


export type ProfitFees = {
    bondsFee: number;
    sharesFee: number;
    finMarketFee: number;
};

export type HistoryParams =
    'shares' |
    'bonds' |
    'startMonth' |
    'startYear' |
    'yieldType' |
    'duration' |
    'initialInvestment' |
    'monthlyInvestment' |
    'investmentFee' |
    'finMarket' |
    'sharesAllocation' |
    'bondsAllocation' |
    'finMarketAllocation' |
    'profitFees' |
    'showFees';

export type BreakPreset = {
    // Na cíl (zero)
    goal: {
        conservative: Array<Array<number>>,
        balanced: Array<Array<number>>,
        dynamic: Array<Array<number>>,
    },
    // Na rentu (30)
    allowance: {
        conservative: Array<Array<number>>,
        balanced: Array<Array<number>>,
        dynamic: Array<Array<number>>,
    },
    // Vlastní
    custom?: {
        shares: Array<number>,
        bonds: Array<number>,
        finMarket: Array<number>,
    }
};

@Injectable({
    providedIn: 'root'
})
export class HistoricalParametersService {

    public parameters: BehaviorSubject<CreateHistoryDataRequest> = new BehaviorSubject<CreateHistoryDataRequest>({
        shares: 1,
        bonds: 0,
        startYear: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').year(),
        startMonth: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month()+1,
        yieldType: 'Nominal',
        duration: 20,
        initialInvestment: 100000,
        monthlyInvestment: 3000,
        investmentFee: 0,
        finMarket: 0,
        sharesAllocation: [
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        ],
        bondsAllocation: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        finMarketAllocation: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        profitFees: {bondsFee: 0, sharesFee: 0, finMarketFee: 0},
        showFees: false,
    });
    sharedParams$: Observable<CreateHistoryDataRequest> = this.parameters.asObservable();

    public modeRatios = {
        dynamic: {
            shares: 1,
            bonds: 0,
            finMarket: 0
        },
        balanced: {
            shares: 0.6,
            bonds: 0.4,
            finMarket: 0
        },
        conservative: {
            shares: 0.5,
            bonds: 0.5,
            finMarket: 0
        }
    };

    private _breakMode: 'none' | 'goal' | 'allowance' | 'custom' = 'none';
    private _allocationMode: 'dynamic' | 'balanced' | 'conservative' | 'custom' = 'dynamic';

    private breakPresets: BreakPreset = {
        goal: {
            conservative: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20]],
            balanced: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 60, 10], [40, 60, 0], [50, 50, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0]],
            dynamic: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 60, 10], [40, 60, 0], [50, 50, 0], [60, 40, 0], [70, 30, 0], [80, 20, 0], [90, 10, 0]],
        },
        allowance: {
            conservative: [[30, 35, 35], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20]],
            balanced: [[44, 28, 28], [50, 25, 25], [54, 23, 23], [60, 20, 20], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0]],
            dynamic: [[60, 20, 20], [64, 18, 18], [68, 16, 16], [72, 14, 14], [76, 12, 12], [80, 10, 10], [84, 8, 8], [88, 6, 6], [92, 4, 4], [96, 2, 2]],
        }
    };

    private _breakChart!: ChartOptions;

    set allocationMode(allocationMode: 'dynamic' | 'balanced' | 'conservative' | 'custom') {
        this._allocationMode = allocationMode;
        this.resetAllocations(this.parameters.value);
        this.parameters.next(this.parameters.value);
    }

    get allocationMode() {
        return this._allocationMode;
    }

    set brakeMode(brakeMode: 'none' | 'goal' | 'allowance' | 'custom') {
        this._breakMode = brakeMode;

        this.resetAllocations(this.parameters.value);
        this.parameters.next(this.parameters.value);

    }

    get brakeMode() {
        return this._breakMode;
    }

    get breaks(): BreakPreset {
        return this.breakPresets;
    }


    getParameters(): CreateHistoryDataRequest {
        return this.parameters.value;
    }

    setParameters(data: CreateHistoryDataRequest): void {
        data = this.resetAllocations(data);
        this.breakPresets.custom = {
            shares: data.sharesAllocation,
            finMarket: data.finMarketAllocation,
            bonds: data.bondsAllocation
        };
        this.parameters.next(data);
    }

    public setBondsAllocationAt(index: number, value: any): void {
        if (value < 0) {
            value = 0;
        } else {
            value = value / 100;
        }

        if (index < this.parameters.value.duration) {
            for (let i = index; i < this.parameters.value.duration; i++) {

                this.parameters.value.bondsAllocation[i] = value;
                this.parameters.value.finMarketAllocation[i] = (1 - (this.parameters.value.bondsAllocation[i] +
                    (this.parameters.value.sharesAllocation[i] ?? 0)) >= 0) ? 1 - (this.parameters.value.bondsAllocation[i] +
                    (this.parameters.value.sharesAllocation[i] ?? 0)) : 0;
                this.parameters.value.sharesAllocation[i] = 1 - (this.parameters.value.bondsAllocation[i] +
                    this.parameters.value.finMarketAllocation[i]);

            }
        }

        this.parameters.next(this.parameters.value);
        this.brakeMode = 'custom';
    }

    public setFinMarketAllocationAt(index: number, value: any): void {
        if (value < 0) {
            value = 0;
        } else {
            value = value / 100;
        }

        if (index < this.parameters.value.duration) {
            for (let i = index; i < this.parameters.value.duration; i++) {
                this.parameters.value.finMarketAllocation[i] = value;
                this.parameters.value.sharesAllocation[i] = (1 - ((this.parameters.value.bondsAllocation[i] ?? 0) +
                    this.parameters.value.finMarketAllocation[i]) >= 0) ? 1 - ((this.parameters.value.bondsAllocation[i] ?? 0) +
                    this.parameters.value.finMarketAllocation[i]) : 0;
                this.parameters.value.bondsAllocation[i] = 1 - (this.parameters.value.sharesAllocation[i] +
                    this.parameters.value.finMarketAllocation[i]);
            }
        }

        this.parameters.next(this.parameters.value);
        this.brakeMode = 'custom';
    }

    public setSharesAllocationAt(index: number, value: number): void {
        if (value < 0) {
            value = 0;
        } else {
            value = value / 100;
        }

        if (index < this.parameters.value.duration) {
            for (let i = index; i < this.parameters.value.duration; i++) {

                this.parameters.value.sharesAllocation[i] = value;
                this.parameters.value.bondsAllocation[i] = (1 - ((this.parameters.value.finMarketAllocation[i] ?? 0) +
                    (this.parameters.value.sharesAllocation[i] ?? 0)) >= 0) ? 1 - ((this.parameters.value.finMarketAllocation[i] ?? 0) +
                    (this.parameters.value.sharesAllocation[i] ?? 0)) : 0;
                this.parameters.value.finMarketAllocation[i] = 1 - ((this.parameters.value.bondsAllocation[i] ?? 0) +
                    (this.parameters.value.sharesAllocation[i] ?? 0));

            }
        }

        this.parameters.next(this.parameters.value);
        this.brakeMode = 'custom';
    }

    public resetAllocations(data: CreateHistoryDataRequest): CreateHistoryDataRequest {

        data.sharesAllocation = Array.from({length: data.duration}, (_, index) => {
            if (index < data.sharesAllocation.length) {
                return data.sharesAllocation[index];
            } else {
                return data.sharesAllocation[data.sharesAllocation.length - 1];
            }
        });

        data.bondsAllocation = Array.from({length: data.duration}, (_, index) => {
            if (index < data.bondsAllocation.length) {
                return data.bondsAllocation[index];
            } else {
                return data.bondsAllocation[data.bondsAllocation.length - 1];
            }
        });

        data.finMarketAllocation = Array.from({length: data.duration}, (_, index) => {
            if (index < data.finMarketAllocation.length) {
                return data.finMarketAllocation[index];
            } else {
                return data.finMarketAllocation[data.finMarketAllocation.length - 1];
            }
        });

        if (this.brakeMode === 'goal') {
            let j = 0;
            for (let i = data.duration - 1; i >= 0; i--) {
                if (j < 10) {
                    data.sharesAllocation[i] = this.breakPresets.goal[this.allocationMode][j][0] / 100;
                    data.bondsAllocation[i] = this.breakPresets.goal[this.allocationMode][j][1] / 100;
                    data.finMarketAllocation[i] = this.breakPresets.goal[this.allocationMode][j][2] / 100;
                    j++;
                }
            }
        } else if (this.brakeMode === 'allowance') {
            let j = 0;
            for (let i = data.duration - 1; i >= 0; i--) {
                if (j < 10) {
                    data.sharesAllocation[i] = this.breakPresets.allowance[this.allocationMode][j][0] / 100;
                    data.bondsAllocation[i] = this.breakPresets.allowance[this.allocationMode][j][1] / 100;
                    data.finMarketAllocation[i] = this.breakPresets.allowance[this.allocationMode][j][2] / 100;
                    j++;
                }
            }
        } else if (this.brakeMode === 'none') {
            data.sharesAllocation.fill(data.shares);
            data.bondsAllocation.fill(data.bonds);
            data.finMarketAllocation.fill(data.finMarket);
        }
        return data;
    }

    public addNotice(mode: string, notice: string) {
        const parameters: any = this.getParameters();
        parameters[`${mode}_notice`] = notice;
        this.parameters.next(parameters);
    }

    public getNotice(mode: string) {
        const parameters: any = this.getParameters();
        return parameters[`${mode.toLowerCase()}_notice`];
    }

    get breakChart(): ChartOptions {
        return this._breakChart;
    }

    set breakChart(value: ChartOptions) {
        this._breakChart = value;
    }

    resetParameters(): void {

        this.modeRatios = {
            dynamic: {
                shares: 1,
                bonds: 0,
                finMarket: 0
            },
            balanced: {
                shares: 0.6,
                bonds: 0.4,
                finMarket: 0
            },
            conservative: {
                shares: 0.5,
                bonds: 0.5,
                finMarket: 0
            }
        };

        this.allocationMode = 'dynamic';
        this.brakeMode = 'none';

        this.breakPresets = {
            goal: {
                conservative: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20]],
                balanced: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 60, 10], [40, 60, 0], [50, 50, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0]],
                dynamic: [[0, 0, 100], [10, 40, 50], [20, 60, 20], [30, 60, 10], [40, 60, 0], [50, 50, 0], [60, 40, 0], [70, 30, 0], [80, 20, 0], [90, 10, 0]],
            },
            allowance: {
                conservative: [[30, 35, 35], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20], [30, 50, 20]],
                balanced: [[44, 28, 28], [50, 25, 25], [54, 23, 23], [60, 20, 20], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0], [60, 40, 0]],
                dynamic: [[60, 20, 20], [64, 18, 18], [68, 16, 16], [72, 14, 14], [76, 12, 12], [80, 10, 10], [84, 8, 8], [88, 6, 6], [92, 4, 4], [96, 2, 2]],
            }
        };

        const defaultParams: CreateHistoryDataRequest = {
            shares: 1,
            bonds: 0,
            startYear: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').year(),
            startMonth: moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month()+1,
            yieldType: 'Nominal',
            duration: 20,
            initialInvestment: 100000,
            monthlyInvestment: 3000,
            investmentFee: 0,
            finMarket: 0,
            sharesAllocation: [
                1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            ],
            bondsAllocation: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            finMarketAllocation: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            profitFees: {bondsFee: 0, sharesFee: 0, finMarketFee: 0},
            showFees: false,
        };

        this.parameters.next(defaultParams);
    }

}
