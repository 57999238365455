import { Route } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard, LayoutComponent, NoAuthGuard } from '../../../../libs/kfp/src';
import { DraftGuard } from '../../../../libs/kfp/src/lib/calculations/draft.guard';
import { ActiveSubscriptionGuard } from './guards/active-subscription.guard';
import { ImpersonateGuard } from '../../../../libs/kfp/src/lib/auth/guards/impersonate.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [ImpersonateGuard, NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () => import('../../../../libs/kfp/src/lib/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule),
            },
            {
                path: 'register',
                loadChildren: () => import('../../../../libs/kfp/src/lib/auth/register/register.module').then(m => m.AuthRegisterModule),
            },
            {
                path: 'confirmation-success',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/auth/confirmation-success/confirmation-success.module').then(
                        m => m.AuthConfirmationSuccessModule,
                    ),
            },
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/auth/confirmation-required/confirmation-required.module').then(
                        m => m.AuthConfirmationRequiredModule,
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/auth/forgot-password/forgot-password.module').then(
                        m => m.AuthForgotPasswordModule,
                    ),
            },
            {
                path: 'changepass',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-with-token',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/auth/token-login/token-login.module').then(m => m.AuthTokenLoginModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('../../../../libs/kfp/src/lib/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [ImpersonateGuard, AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('../../../../libs/kfp/src/lib/dashboard/project/project.module').then(m => m.ProjectModule),
            },
            {
                path: 'historical',
                canDeactivate: [DraftGuard],
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/calculations/historical/historical.module').then(m => m.HistoricalModule),
                canActivate: [ActiveSubscriptionGuard],
            },
            {
                path: 'profit',
                canDeactivate: [DraftGuard],
                loadChildren: () => import('../../../../libs/kfp/src/lib/calculations/estates/estates.module').then(m => m.EstatesModule),
                canActivate: [ActiveSubscriptionGuard],
            },
            {
                path: 'probability',
                canDeactivate: [DraftGuard],
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/calculations/probability/probability.module').then(m => m.ProbabilityModule),
                canActivate: [ActiveSubscriptionGuard],
            },
            {
                path: 'fin_math',
                canDeactivate: [DraftGuard],
                loadChildren: () => import('../../../../libs/kfp/src/lib/calculations/finmath/finmath.module').then(m => m.FinMathModule),
                canActivate: [ActiveSubscriptionGuard],
            },
            {
                path: 'mortgage',
                canDeactivate: [DraftGuard],
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/calculations/mortgage/mortgage.module').then(m => m.MortgageModule),
                canActivate: [ActiveSubscriptionGuard],
            },
            {
                path: 'support',
                loadChildren: () => import('../../../../libs/kfp/src/lib/pages/support/support.module').then(m => m.SupportModule),
            },
            {
                path: 'changelog',
                loadChildren: () => import('../../../../libs/kfp/src/lib/pages/changelog/changelog.module').then(m => m.ChangelogModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('../../../../libs/kfp/src/lib/profile/profile.module').then(m => m.ProfileModule),
            },
            {
                path: 'aktuality',
                loadChildren: () => import('../../../../libs/kfp/src/lib/news/news.module').then(m => m.NewsModule),
                canActivate: [ActiveSubscriptionGuard],
            },
            {
                path: 'calculations',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/calculations/calculations-overview/calculations-overview.module').then(
                        m => m.CalculationsOverviewModule,
                    ),
                canActivate: [ActiveSubscriptionGuard],
            },

            // maintenance, error & Catch all
            {
                path: 'maintenance',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
            },
            {
                path: '500-internal-server-error',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/pages/error/error-500/error-500.module').then(m => m.Error500Module),
            },
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import('../../../../libs/kfp/src/lib/pages/error/error-404/error-404.module').then(m => m.Error404Module),
            },
            { path: '**', redirectTo: '404-not-found' },
        ],
    },
];
