import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../../auth/auth.service';
import {ChartOptions} from '../services/estates-chart.service';
import {SeriesLabel} from '../services/estates.service';

@Injectable({
    providedIn: 'root',
})
export class LongtermEndflowChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();
    constructor(private authService: AuthService) {
        this.updateChartHeight();

        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 150px, 150px auto; ',
                type: 'bar',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: true,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    barHeight: '40%',
                    horizontal: false,
                    distributed: true,
                    rangeBarOverlap: true,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'top',
                        hideOverflowingLabels: false,
                    },
                },
            },
            colors: [
                ({ value }) => {
                    if (value < 0) {
                        return '#E84E0E';
                    } else {
                        return '#1390e1';
                    }
                }
            ] as any,

            xaxis: {
                type: 'category',
                tooltip: {
                    enabled: false,
                },
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            dataLabels: {
                offsetY: -25,
                formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                style: {
                    fontSize: '12px',
                    colors: ['#002B4F'],
                },
            },
            legend: {
                show: false,
                showForSingleSeries: true,
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            tooltip: {
                enabled: false,
            },
            responsive: {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        this.logo = this.authService.getLogoPath();
        this.updateChartHeight();
        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = { ...currentObject };
            updatedObject.series = newValue.series;
            if (newValue.colors) {
                updatedObject.colors = newValue.colors;
            }

            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 150px, 150px auto; ';
            }
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }

    updateChartHeight(): void {
        if (window.innerWidth > 1425) {
            this.height = window.innerHeight * (1 / 2);
        } else if (window.innerWidth > 995) {
            this.height = window.innerHeight * (3 / 5);
        } else {
            this.height = window.innerHeight - 430;
        }
    }
}
