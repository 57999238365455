import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {CreateRealEstateDataRequest} from 'kfp';
import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill, ApexGrid,
    ApexLegend,
    ApexMarkers,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';
import {combineLatest, map, Observable, switchMap} from 'rxjs';
import {EstatesService} from './estates.service';
import {StartCashflowChart} from '../charts/start-cashflow-chart';
import {InvestmentEvaluationChart} from '../charts/investment-evaluation-chart';
import {LongtermEvolutionChart} from '../charts/longterm-evolution-chart';
import {InvestmentEndflowChart} from '../charts/investment-endflow-chart';
import {LongtermPieStartChart} from '../charts/longterm-pie-start-chart';
import {LongtermPieEndChart} from '../charts/longterm-pie-end-chart';
import {LongtermEndflowChart} from '../charts/longterm-endflow-chart';
import {EstateFlowChart} from '../charts/estate-flow-chart';

export type EstatesChartType = 'START_CASHFLOW' | 'LONG_CASHFLOW' | 'INVESTMENTS' | 'ESTATE_FLOW';
export type EstatesChartOptions = {
    params?: any;
};

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis?: ApexXAxis;
    stroke?: ApexStroke;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis;
    title?: ApexTitleSubtitle;
    labels?: string[];
    legend?: ApexLegend;
    subtitle?: ApexTitleSubtitle;
    plotOptions?: ApexPlotOptions;
    colors: string[];
    tooltip: ApexTooltip;
    fill?: ApexFill;
    markers?: ApexMarkers;
    responsive?: ApexResponsive;
    annotations?: ApexAnnotations;
    grid?: ApexGrid;
    absoluteSeries?: ApexAxisChartSeries;
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class EstatesChartService {

    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _estatesService: EstatesService,
        private readonly _startCashflowChart: StartCashflowChart,
        private readonly _investmentEvaluationChart: InvestmentEvaluationChart,
        private readonly _longtermEvolutionChart: LongtermEvolutionChart,
        private readonly _investmentEndflowChart: InvestmentEndflowChart,
        private readonly _longtermPieStartChart: LongtermPieStartChart,
        private readonly _longtermPieEndChart: LongtermPieEndChart,
        private readonly _longtermEndFlow: LongtermEndflowChart,
        private readonly _estateFlowChart: EstateFlowChart,

    ) {
    }

    public chartByType(type: EstatesChartType, parameters: CreateRealEstateDataRequest, options?: EstatesChartOptions) {
        switch (type) {
            case 'START_CASHFLOW':
                return [this.barStartCashFlow(parameters)];
            case 'LONG_CASHFLOW':
                return [this.longtermEvolutionChart(parameters)]
            case 'ESTATE_FLOW':
                return [this.estateFlowLineChart(parameters)];
            case 'INVESTMENTS':
                return [this.investmentEvaluation(parameters)];
        }
    }

    public barStartCashFlow(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.dataBarStartCashFlow(parameters, 'VALUE').pipe(
            switchMap((res) => {
                this._startCashflowChart.updateSeries(res);
                return this._startCashflowChart.getChartData();
            })
        );
    }

    public estateFlowLineChart(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.estateFlowLineChart(parameters, 'VALUE').pipe(
            switchMap((res) => {
                this._estateFlowChart.updateSeries(res);
                return this._estateFlowChart.getChartData();
            })
        );
    }

    public longTermPieStart(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.longTermPieStart(parameters, 'VALUE').pipe(
            switchMap((res) => {
                this._longtermPieStartChart.updateSeries(res);
                return this._longtermPieStartChart.getChartData();
            })
        );
    }

    public longTermPieEnd(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.longTermPieEnd(parameters, 'VALUE').pipe(
            switchMap((res) => {
                this._longtermPieEndChart.updateSeries(res);
                return this._longtermPieEndChart.getChartData();
            })
        );
    }

    public longTermEndFlow(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.longTermEndFlow(parameters, 'VALUE').pipe(
            switchMap((res) => {
                this._longtermEndFlow.updateSeries(res);
                return this._longtermEndFlow.getChartData();
            })
        );
    }

    public investmentEvaluation(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.dataBarInvestmentEval(parameters, 'VALUE').pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._investmentEvaluationChart.updateSeries(res);
                return this._investmentEvaluationChart.getChartData();
            })
        );
    }

    public investmentEndflowChart(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.dataBarInvestmentEndFlow(parameters, 'VALUE').pipe(
            switchMap((res) => {
                this._investmentEndflowChart.updateSeries(res);
                return this._investmentEndflowChart.getChartData();
            })
        );
    }

    public longtermEvolutionChart(parameters: CreateRealEstateDataRequest): Observable<ChartOptions> {
        return this._estatesService.longTermEvolution(parameters, 'VALUE').pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._longtermEvolutionChart.updateSeries(res);
                return this._longtermEvolutionChart.getChartData();
            })
        );
    }

    prepareDataForPdfExport(parameters: CreateRealEstateDataRequest): Observable<{
        barStartCashFlow: ChartOptions;
        estateFlow: ChartOptions;
        investmentEvaluation: ChartOptions;
        longtermEvolutionChart: ChartOptions;
    }> {
        return combineLatest([
            this.barStartCashFlow(parameters),
            this.estateFlowLineChart(parameters),
            this.investmentEvaluation(parameters),
            this.longtermEvolutionChart(parameters),
        ]).pipe(
            map(([
                     barStartCashFlow,
                     estateFlow,
                     investmentEvaluation,
                     longtermEvolutionChart
                 ]) => ({
                barStartCashFlow: barStartCashFlow,
                estateFlow: estateFlow,
                investmentEvaluation: investmentEvaluation,
                longtermEvolutionChart: longtermEvolutionChart
            }))
        );
    }

}
