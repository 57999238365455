
export const kfpColors = {
    // Figma colors

    // Historie investic
    kfpDepositAll: '#E5E5EA', // Vloženo celkem (+finmath)
    kfpProfit: '#9DCE90', // Zisk (+estate)
    kfpInvestProfit: '#487331', // Hodnota investovaných prostředků (+finmath)

    kfpStocks: '#70AD1F', // Akcie
    kfpBonds: '#1078BB', // Dluhopisy
    kfpMarket: '#8E6DEE', // Peněžní trh
    kfpInflation: '#E7357F', // Inflace

    kfpNominal: '#9DCE90', // Nominální
    kfpReal: '#487331', // Reálný

    // Pravděpodobnost

    kfpProbProfit: '#76B02A',
    kfpDepositValue: '#E5E5EA', // Vložená částka / Vloženo celkem

    // Pravděpodobnost - Profit
    kfpPesimistic: '#9C9C9C', // Pesimistická
    kfpRealistic: '#76B02A', // Realistická
    kfpOptimistic: '#C7E47C', // Optimistická

    // Finanční matematika
    kfpHypoDefault: '#60a5fa', // Hypotéka (+profit)
    kfpRent: '#60a5fa', // Renta




    // Výnosnost nemovitostí - 1 tab
    kfpProfitPlus: '#76B02A', // Příjmy kladne
    kfpProfitMinus: '#307bcf', // Příjmy zaporne
    kfpProfitOtherExpenses: '#307bcf', // Ostatní výdaje

    // Výnosnost nemovitostí 2tab (profit / estate)
    kfpEvaulation: '#257F2B', // Zhodnocení
    kfpEstateDeposits: '#307bcf', // Měsíční náklady (estate)

    // Výnosnost nemovitostí - 3,4 tab
    kfpEstatePrice: '#76B02A', // Cena nemovitosti
    kfpEstateProperty: '#487331', // Majetek
    kfpCashFlow: '#EC6C36', // CashFlow estate (4tab)



    // Hypotéka / Investice
    kfpInvestDefault: '#76B02A', // Investice (default)



    kfpOrange: '#EC6C36' // Oranžová KFP
};

