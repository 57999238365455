import {formatNumber} from '@angular/common';
import {Injectable, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ChartOptions} from '../services/probability-chart.service';
import {SeriesLabel} from '../services/probability.service';
import {AuthService} from '../../../../auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {kfpColors} from '../../../../../../../../apps/fuse/src/assets/colors';

@Injectable({
    providedIn: 'root',
})
export class ProbabilityBasicChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();

    constructor(private authService: AuthService, private translateService: TranslateService) {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 70) / 100;
        } else {
            this.height = (window.innerHeight * 65) / 100 - 75;
        }
        this.translateService
            .stream([
                'probability.calculation.chart.pessimist_1',
                'probability.calculation.chart.average',
                'probability.calculation.chart.optimistic_1',
            ])
            .subscribe((keys) => {
                this.logo = this.authService.getLogoPath();
                this.dataChart.next({
                    series: [],
                    chart: {
                        background:
                            'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                            this.logo +
                            ') no-repeat center; background-size: clamp(100px, 30%, 150px)',
                        toolbar: {
                            tools: {
                                download: false,
                            },
                        },
                        type: 'bar',
                        height: this.height,
                        stacked: true,
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 8,
                            barHeight: '40%',
                            horizontal: true,
                            dataLabels: {
                                position: 'top',
                                hideOverflowingLabels: false,
                            },
                            borderRadiusApplication: 'end',
                        },
                    },
                    colors: [kfpColors.kfpDepositValue, kfpColors.kfpProbProfit],
                    xaxis: {
                        categories: [
                            keys['probability.calculation.chart.pessimist_1'],
                            keys['probability.calculation.chart.average'],
                            keys['probability.calculation.chart.optimistic_1'],
                        ],
                        labels: {
                            style: {
                                colors: '#8E8E93',
                                fontSize: '12px',
                            },
                            formatter: (value: any) => formatNumber(value, 'cs', '1.0') + ' ' + this.authService.currency,
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: '#8E8E93',
                                fontSize: '12px',
                            },
                        },
                    },
                    dataLabels: {
                        enabledOnSeries: [1],
                        offsetY: -8,
                        textAnchor: window.innerWidth > 720 ? 'middle' : 'start',
                        offsetX: window.innerWidth > 720 ? 0 : 10,
                        // @ts-ignore
                        formatter: (_val: number, opt: any) => {
                            const series = opt.w.config.series;
                            const idx = opt.dataPointIndex;
                            const total = series.reduce((total1: number, self: any) => self.labels[idx].a, 0);
                            const percent = series.reduce((percent1: number, self: any) => self.labels[idx].b, 0) * 100;
                            return [
                                formatNumber(percent, 'cs', '1.2-2') + ' % p.a.',
                                formatNumber(total, 'cs', '1.0-0') + ' ' + this.authService.currency,
                            ];
                        },
                        style: {
                            fontSize: '12px',
                            colors: ['#002B4F'],
                        },
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'left',
                        itemMargin: {
                            horizontal: 25,
                            vertical: 25,
                        },
                        markers: {
                            radius: 5,
                            width: 15,
                            height: 8,
                            offsetY: -4,
                        },
                        labels: {
                            colors: '#95959a',
                        },
                    },
                    tooltip: {
                        intersect: false,
                        shared: true,
                        custom: (opt: any) => {
                            const dataPointIndex = opt.dataPointIndex;
                            const series = opt.ctx.opts.series;

                            const total = series.reduce((total2: number, self: any) => self.labels[dataPointIndex].a, 0);

                            const percent = series.reduce((percen2: number, self: any) => self.labels[dataPointIndex].b, 0) * 100;

                            return (
                                '<div class="apexcharts-theme-light">' +
                                '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
                                this.translateService.instant('probability.calculation.chart.average') +
                                '</div>' +
                                '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
                                '<span class="apexcharts-tooltip-marker" style="background-color:#D1D1D6"></span>' +
                                '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
                                '<div class="apexcharts-tooltip-y-group">' +
                                '<span class="apexcharts-tooltip-text-label">' +
                                this.translateService.instant('probability.calculation.chart.sum') +
                                ': </span>' +
                                '<span class="apexcharts-tooltip-text-value">' +
                                formatNumber(total, 'cs', '1.0-0') +
                                '&nbsp;' +
                                this.authService.currency +
                                '</span>' +
                                '</div>' +
                                '<div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div>' +
                                '</div>' +
                                '</div>' +
                                '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
                                '<span class="apexcharts-tooltip-marker" style="background-color:#FADCCF"></span>' +
                                '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
                                '<div class="apexcharts-tooltip-y-group">' +
                                '<span class="apexcharts-tooltip-text-label">' +
                                this.translateService.instant('probability.calculation.chart.inserted_amount') +
                                ': </span>' +
                                '<span class="apexcharts-tooltip-text-value">' +
                                formatNumber(opt.series[0][dataPointIndex], 'cs', '1.0-0') +
                                '&nbsp;' +
                                this.authService.currency +
                                '</span>' +
                                '</div>' +
                                '<div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div>' +
                                '</div>' +
                                '</div>' +
                                '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
                                '<span class="apexcharts-tooltip-marker" style="background-color:#6BD3BF"></span>' +
                                '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
                                '<div class="apexcharts-tooltip-y-group">' +
                                '<span class="apexcharts-tooltip-text-label">' +
                                this.translateService.instant('probability.calculation.chart.profit') +
                                ': </span>' +
                                '<span class="apexcharts-tooltip-text-value">' +
                                formatNumber(opt.series[1][dataPointIndex], 'cs', '1.0-0') +
                                '&nbsp;' +
                                this.authService.currency +
                                '</span>' +
                                '</div>' +
                                '<div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div>' +
                                '</div>' +
                                '</div>' +
                                '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
                                '<span class="apexcharts-tooltip-marker" style="background-color:#FADCCF"></span>' +
                                '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
                                '<div class="apexcharts-tooltip-y-group">' +
                                '<span class="apexcharts-tooltip-text-label">' +
                                this.translateService.instant('probability.calculation.chart.evaluation') +
                                ': </span>' +
                                '<span class="apexcharts-tooltip-text-value">' +
                                formatNumber(percent, 'cs', '1.2-2') +
                                '% p.a.</span>' +
                                '</div>' +
                                '<div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div>' +
                                '</div>' +
                                '</div>' +
                                '</div>'
                            );
                        },
                    },
                });
            });
    }

    updateSeries(newValue: SeriesLabel): void {
        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = {...currentObject};
            updatedObject.series = newValue.series;

            this.logo = this.authService.getLogoPath();
            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 150px, 150px auto; ';
            }

            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}
