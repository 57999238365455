import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of, switchMap} from 'rxjs';
import {ApexAxisChartSeries} from 'ng-apexcharts';
import {CreateRealEstateDataRequest, FinRealEstateService, RealEstateDataDto} from 'kfp';

export type SeriesLabel = {
    series: ApexAxisChartSeries;
    labels: string[];
    min?: number;
    max?: number;
    data?: RealEstateDataDto;
    colors?: string[];
    tooltipData?: any;
};

export type ValueMode = 'VALUE' | 'PERCENT';

@Injectable({
    providedIn: 'root',
})
export class EstatesService {
    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _estatesService: FinRealEstateService
    ) {
    }

    dataSummary(dataRequest: CreateRealEstateDataRequest): Observable<RealEstateDataDto> {
        return this._estatesService.realEstateDate(dataRequest);
    }

    dataBarStartCashFlow(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel[]> {
        return this._estatesService.realEstateDate(dataRequest).pipe(
            map((data) => {
                const seriesLabels: SeriesLabel[] = [];

                const globalMin = 0;
                let globalMax = Number.MIN_VALUE;


                data.cashFlowByTimeResults?.forEach((item) => {
                    const series: SeriesLabel = {
                        series: [{
                            name: this.translateService.instant('estates.calculation.chart.profit'),
                            data: [item.extendedIncomes, 0, 0],
                        }, {
                            name: this.translateService.instant('estates.calculation.chart.hypo'),
                            data: [0, -(item.extendedExpenses?.mortgagePayment ?? 0), 0],
                        }, {
                            name: this.translateService.instant('estates.calculation.chart.other_expenses'),
                            data: [0, -(item.extendedExpenses?.otherExpensesResult ?? 0), 0],
                        }, {
                            name: this.translateService.instant('estates.calculation.chart.sum'),
                            data: [0, 0, item.result],
                        }],
                        labels: [item.year.toString()],
                        min: 0,
                        max: 0,
                        data: data,
                    };

                    const maxCandidate: number[] = [
                        item.result,
                        item.extendedIncomes,
                        (-(item.extendedExpenses?.otherExpensesResult ?? 0) + -(item.extendedExpenses?.mortgagePayment ?? 0))
                    ];
                    const max: number = Math.max(...maxCandidate);
                    globalMax = Math.max(globalMax, max);

                    series.tooltipData = {
                        incomes: item.extendedIncomes,
                        hypo: -(item.extendedExpenses?.mortgagePayment ?? 0),
                        result: item.result,
                        otherExpenses: -(item.extendedExpenses?.otherExpensesResult ?? 0),
                        allExpenses: item.extendedExpenses?.otherExpenses && Array.isArray(item.extendedExpenses?.otherExpenses)
                            ? item.extendedExpenses.otherExpenses.map((expense: any) => {
                                const {name, value} = expense;
                                return {name, value};
                            })
                            : null
                    };

                    seriesLabels.push(series);

                });

                seriesLabels.forEach((label) => {
                    label.min = globalMin;
                    label.max = globalMax;
                });

                return seriesLabels;
            })
        );
    }


    dataBarInvestmentEndFlow(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel[]> {
        return this._estatesService.realEstateDate(dataRequest).pipe(map(data => this.generateDataBarInvestmentEndFlow(data)));
    }

    generateDataBarInvestmentEndFlow(data: RealEstateDataDto): SeriesLabel[] {
        const seriesLabels: SeriesLabel[] = [];

        // Loop through investmentByTimeResults array and generate series for each item
        data.investmentByTimeResults?.forEach((item) => {
            const series: ApexAxisChartSeries = [
                {
                    name: this.translateService.instant('estates.calculation.chart.profit'),
                    data: [
                        {
                            x: this.translateService.instant('estates.calculation.params.property_price'),
                            y: item?.realEstatePrice as number,
                        },
                        {
                            x: this.translateService.instant('estates.calculation.params.not_paid'),
                            y: item?.loanAmount as number,
                        },
                        {
                            x: this.translateService.instant('estates.calculation.params.sum_cashflow'),
                            y: item?.cashFlow?.result as number,
                        },
                        {
                            x: this.translateService.instant('estates.calculation.params.property_value'),
                            y: item?.totalValueEstate as number,
                        },
                    ],
                },
            ];

            const minCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec => Math.min(rec.result, rec.extendedIncomes));
            const min: number = minCandidate ? Math.min(...minCandidate) : 0;

            const maxCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec => Math.max(rec.result, rec.extendedIncomes));
            const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

            const labels: string[] = data.cashFlowByTimeResults?.map(rec => rec.year + '') as string[];

            seriesLabels.push({series: series, labels: labels, min: min, max: max, data: data});
        });

        return seriesLabels;
    }

    dataBarInvestmentEval(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel[]> {
        return this._estatesService.realEstateDate(dataRequest).pipe(map(data => this.generateDataBarInvestmentEval(data)));
    }

    generateDataBarInvestmentEval(data: RealEstateDataDto): SeriesLabel[] {
        const seriesLabels: SeriesLabel[] = [];

        const globalMin = 0;
        let globalMax = Number.MIN_VALUE;

        data.investmentByTimeResults?.forEach((item) => {
            const series: SeriesLabel = {
                series: [{
                    name: 'Cena nemovitosti v daném roce',
                    data: [item?.realEstatePrice, 0, 0],
                }, {
                    name: 'Nesplacená výše hypo',
                    data: [0, item?.loanAmount, 0],
                }, {
                    name: 'Rozdíl majetek - závazky',
                    data: [0, 0, item?.totalValueEstate],
                }, {
                    name: 'Suma cashflow kladné',
                    data: [item?.cashFlow.incomes, 0, 0],
                }, {
                    name: 'Suma záporné cashflow',
                    data: [0, item?.cashFlow.monthlyCostsResult + item?.cashFlow.mortgagePayment, 0],
                }],
                labels: [],
                min: 0,
                max: 0,
                data: data,
            };

            const maxCandidate: number[] | undefined = data.investmentByTimeResults?.map(rec =>
                Math.max(
                    rec.realEstatePrice + item?.cashFlow.incomes,
                    rec.loanAmount + rec.cashFlow.monthlyCostsResult + item?.cashFlow.mortgagePayment,
                    rec?.totalValueEstate
                )
            );
            const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;
            globalMax = Math.max(globalMax, max);

            seriesLabels.push(series);
        });

        seriesLabels.forEach((label) => {
            label.min = globalMin;
            label.max = globalMax;
        });

        return seriesLabels;
    }

    longTermEvolution(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel> {
        return this._estatesService.realEstateDate(dataRequest).pipe(
            switchMap((data) => {

                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('estates.calculation.chart.month_profit'),
                        data: data.cashFlowByTimeResults?.map(year => year.extendedIncomes) as number[],
                        type: 'area'
                    },
                    {
                        name: this.translateService.instant('estates.calculation.chart.month_expenses'),
                        data: data.cashFlowByTimeResults?.map(year => year.extendedExpenses?.result) as number[],
                        type: 'area'
                    },
                ];

                const minCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.min(rec.result, rec.extendedIncomes)
                );
                const min: number = minCandidate ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.max(rec.result, rec.extendedIncomes)
                );
                const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.cashFlowByTimeResults?.map(rec => rec.year + '') as string[];
                return of({
                    series: series, labels: labels, min: min, max: max, data: data,
                    tooltipData: {
                        mortgagePayment: data.cashFlowByTimeResults?.map(year => year.extendedExpenses?.mortgagePayment) as number[],
                        otherExpenses: data.cashFlowByTimeResults?.map(year => year.extendedExpenses?.otherExpensesResult) as number[],
                        allExpenses: data.cashFlowByTimeResults?.map((year: any) => {
                            if (year.extendedExpenses?.otherExpenses && Array.isArray(year.extendedExpenses?.otherExpenses)) {
                                return year.extendedExpenses?.otherExpenses?.map((expense: any) => {
                                    const {name, value} = expense;
                                    return {name, value};
                                });
                            } else {
                                return null;
                            }
                        })
                    }
                } as SeriesLabel);
            })
        );
    }


    longTermEndFlow(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel> {
        return this._estatesService.realEstateDate(dataRequest).pipe(
            switchMap((data) => {
                const lastObject =
                    data.cashFlowByTimeResults && data.cashFlowByTimeResults.length > 0
                        ? data.cashFlowByTimeResults[data.cashFlowByTimeResults.length - 1]
                        : null;
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('estates.calculation.chart.profit'),
                        data: [
                            {
                                x: this.translateService.instant('estates.calculation.params.rent_incomes'),
                                y: lastObject?.extendedIncomes as number,
                            },
                            {
                                x: this.translateService.instant('estates.calculation.params.mortgage_pay'),
                                y: -lastObject?.extendedExpenses?.mortgagePayment as number,
                            },
                            {
                                x: this.translateService.instant('estates.calculation.params.expenses'),
                                y: -lastObject?.extendedExpenses?.otherExpensesResult as number,
                            },
                            {
                                x: this.translateService.instant('estates.calculation.params.sum_cashflow'),
                                y: lastObject?.result as number,
                            },
                        ],
                    },
                ];

                const minCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.min(rec.result, rec.extendedIncomes)
                );
                const min: number = minCandidate ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.max(rec.result, rec.extendedIncomes)
                );
                const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.cashFlowByTimeResults?.map(rec => rec.year + '') as string[];
                return of({series: series, labels: labels, min: min, max: max, data: data} as SeriesLabel);
            })
        );
    }

    longTermPieStart(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel> {
        return this._estatesService.realEstateDate(dataRequest).pipe(
            switchMap((data) => {
                const firstObject =
                    data.cashFlowByTimeResults && data.cashFlowByTimeResults.length > 0 ? data.cashFlowByTimeResults[0] : null;

                const series = [firstObject?.extendedIncomes as number, firstObject?.extendedExpenses?.result as number];

                const minCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.min(rec.result, rec.extendedIncomes)
                );
                const min: number = minCandidate ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.max(rec.result, rec.extendedIncomes)
                );
                const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.cashFlowByTimeResults?.map(rec => rec.year + '') as string[];
                return of({series: series, labels: labels, min: min, max: max, data: data} as any);
            })
        );
    }

    longTermPieEnd(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel> {
        return this._estatesService.realEstateDate(dataRequest).pipe(
            switchMap((data) => {
                const lastObject =
                    data.cashFlowByTimeResults && data.cashFlowByTimeResults.length > 0
                        ? data.cashFlowByTimeResults[data.cashFlowByTimeResults.length - 1]
                        : null;

                const series = [lastObject?.extendedIncomes as number, lastObject?.extendedExpenses?.result as number];

                const minCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.min(rec.result, rec.extendedIncomes)
                );
                const min: number = minCandidate ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.max(rec.result, rec.extendedIncomes)
                );
                const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.cashFlowByTimeResults?.map(rec => rec.year + '') as string[];
                return of({series: series, labels: labels, min: min, max: max, data: data} as any);
            })
        );
    }

    estateFlowLineChart(dataRequest: CreateRealEstateDataRequest, valueMode: ValueMode = 'PERCENT'): Observable<SeriesLabel> {
        return this._estatesService.realEstateDate(dataRequest).pipe(
            switchMap((data) => {
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('estates.calculation.params.estateValue'),
                        data: data.investmentByTimeResults?.map(year => year.totalValueEstate) as number[],
                        type: 'area'
                    },
                    {
                        name: this.translateService.instant('estates.calculation.params.property_price'),
                        data: data.investmentByTimeResults?.map(year => year.realEstatePrice) as number[],
                        type: 'area'
                    },
                    {
                        name: this.translateService.instant('estates.calculation.tab.mortgage'),
                        data: data.investmentByTimeResults?.map(year => -(year.loanAmount)) as number[],
                        type: 'area'
                    },
                    {
                        name: this.translateService.instant('estates.calculation.params.cashFlow'),
                        data: data.investmentByTimeResults?.map(year => year.cashFlow?.result) as number[],
                        type: 'area'
                    },
                ];

                const minCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.min(rec.result, rec.extendedIncomes)
                );
                const min: number = minCandidate ? Math.min(...minCandidate) : 0;

                const maxCandidate: number[] | undefined = data.cashFlowByTimeResults?.map(rec =>
                    Math.max(rec.result, rec.extendedIncomes)
                );
                const max: number = maxCandidate ? Math.max(...maxCandidate) : 0;

                const labels: string[] = data.cashFlowByTimeResults?.map(rec => rec.year + '') as string[];
                return of({series: series, labels: labels, min: min, max: max, data: data} as SeriesLabel);
            })
        );
    }
}
