import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartOptions } from '../services/probability-chart.service';
import { SeriesLabel } from '../services/probability.service';
import { AuthService } from '../../../../auth/auth.service';
import {kfpColors} from '../../../../../../../../apps/fuse/src/assets/colors';

@Injectable({
    providedIn: 'root',
})
export class ProbabilityProfitChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();

    constructor(private authService: AuthService) {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 70) / 100;
        } else {
            this.height = (window.innerHeight * 65) / 100 - 75;
        }
        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 150px, 150px auto; ',
                type: 'bar',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: true,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    horizontal: false,
                    rangeBarOverlap: false,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        hideOverflowingLabels: true,
                        position: 'top',
                    },
                },
            },
            colors: [kfpColors.kfpPesimistic, kfpColors.kfpRealistic, kfpColors.kfpOptimistic],
            xaxis: {
                type: 'category',
                categories: [],
                tooltip: {
                    enabled: false,
                },
                floating: true,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                min: 0,
                max: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: number) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                showForSingleSeries: true,
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                enabled: true,
                y: {
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
                x: {
                    formatter: (value: any) => value,
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        this.logo = this.authService.getLogoPath();

        const currentObject = this.dataChart.value;
        if (currentObject) {
            let updatedObject = { ...currentObject };
            updatedObject.series = newValue.series;
            if (updatedObject.xaxis) {
                updatedObject.xaxis.categories = newValue.labels;
            }
            const yaxis = Object.assign({}, updatedObject.yaxis);
            yaxis.min = newValue.min;
            yaxis.max = newValue.max;
            updatedObject = { ...updatedObject, yaxis: yaxis };

            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 150px, 150px auto; ';
            }

            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}
