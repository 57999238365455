import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../../auth/auth.service';
import {ChartOptions} from '../services/estates-chart.service';
import {SeriesLabel} from '../services/estates.service';
import {TranslateService} from '@ngx-translate/core';
import {kfpColors} from '../../../../../../../../apps/fuse/src/assets/colors';

@Injectable({
    providedIn: 'root',
})
// tab 3 - Vývoj majetku v čase
export class EstateFlowChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();
    constructor(private authService: AuthService, private translateService: TranslateService) {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 70) / 100;
        } else if (window.innerWidth > 995) {
            this.height = window.innerHeight - 380;
        } else {
            this.height = window.innerHeight - 480;
        }

        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 150px, 150px auto; ',
                type: 'line',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
                defaultLocale: 'cs',
                locales: [{
                    name: 'cs',
                    options: {
                        toolbar: {
                            reset: this.translateService.instant('apexchart.toolbar.reset'),
                            pan: this.translateService.instant('apexchart.toolbar.pan'),
                            zoomIn: this.translateService.instant('apexchart.toolbar.zoomIn'),
                            zoomOut: this.translateService.instant('apexchart.toolbar.zoomOut'),
                            selection: this.translateService.instant('apexchart.toolbar.selection'),
                            download: this.translateService.instant('apexchart.toolbar.download'),
                            exportToCSV: this.translateService.instant('apexchart.toolbar.exportToCSV')
                        }
                    }
                }],
            },
            stroke: {
                curve: ['smooth', 'smooth', 'smooth', 'smooth'],
                width: [4, 4, 4, 4],
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    barHeight: '40%',
                    horizontal: false,
                    distributed: true,
                    rangeBarOverlap: true,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'top',
                        hideOverflowingLabels: false,
                    },
                },
            },
            colors: [kfpColors.kfpEstateProperty, kfpColors.kfpEstatePrice, kfpColors.kfpHypoDefault, kfpColors.kfpCashFlow],
            fill: {
                type: ['gradient', 'gradient', 'gradient', 'gradient'],
                colors: [kfpColors.kfpEstateProperty, kfpColors.kfpEstatePrice, kfpColors.kfpHypoDefault, kfpColors.kfpCashFlow],
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.4,
                    opacityFrom: 0.7,
                    opacityTo: 0.1,
                    stops: [0, 100],
                },
            },
            xaxis: {
                type: 'category',
                tooltip: {
                    enabled: false,
                },
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value - 1, 'cs', '1.0-0'),
                },
            },
            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            dataLabels: {
                offsetY: -25,
                formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                style: {
                    fontSize: '12px',
                    colors: ['#1390e1', '#E84E0E'],
                },
            },
            annotations: {
                yaxis: [{
                    y: 0,
                    borderColor: 'rgba(0,0,0,0.8)',
                    strokeDashArray: 0,
                }]
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            tooltip: {
                enabled: true,
            },
            responsive: {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        this.logo = this.authService.getLogoPath();

        const currentObject = this.dataChart.value;
        if (currentObject) {
            const updatedObject = { ...currentObject };
            updatedObject.series = newValue.series;
            if (newValue.colors) {
                updatedObject.colors = newValue.colors;
            }

            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 150px, 150px auto; ';
            }
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}
