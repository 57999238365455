import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
    BehaviorSubject,
    catchError,
    combineLatestWith,
    from,
    map,
    Observable,
    of,
    retry,
    startWith,
    Subject,
    switchMap,
    take,
} from 'rxjs';

import { ProfileService } from '../../profile/profile.service';
import {
    CompanyLicenceResource,
    ProductResource,
    StripeService,
    SubscriptionsResource,
    UserLicenceResource,
    UserResource,
} from '../../api';
import { StripeCardComponent } from '../../profile/details/subscriptions/card/stripe-card.component';
import {
    loadStripe,
    PaymentIntentResult,
    PaymentMethod,
    SetupIntentResult,
    Stripe,
    StripeCardElement,
    StripeElementStyle,
} from '@stripe/stripe-js';
import { environment } from '../../../../../../apps/fuse/src/environments/environment';
import { UserService } from '../../users/users.service';
import moment from 'moment';

export interface KfpPaymentIntent {
    threeDrequired: boolean;
    threeDUrl?: SafeResourceUrl | string;
}

export interface KfpCreatePayment {
    paymentMethod: PaymentMethod;
    needThreeDSecure: boolean;
}

export interface Product {
    id: string;
    name: string;
    priceId: string;
    price: number;
    priceWithTax: number;
    priceWithTaxId: string;
    currency: string;
}

export enum SubscriptionsNamesDev {
    historical = 'Historie investic',
    probability = 'Pravděpodobnost výnosů',
    finmath = 'Finanční matematika',
    standard = 'Standard',
    estates = 'Property For Rent',
    mortgage = 'Mortgage And Investment',
    currentEvents = 'Aktuality',
}
export enum SubscriptionsNamesProd {
    historical = 'Historie investic',
    probability = 'Pravděpodobnost výnosů',
    finmath = 'Finanční matematika',
    standard = 'Roční předplatné',
    estates = 'Property For Rent',
    mortgage = 'Mortgage And Investment',
    currentEvents = 'Aktuality',
}

@Injectable({
    providedIn: 'root',
})
export class KfpStripeService {
    public loaded = false;

    public stripe?: Stripe;
    stripeLoaded = false;
    public subscriptionsLoaded$ = new BehaviorSubject<boolean>(false);
    public products = new BehaviorSubject<Product[]>(new Object() as Product[]);

    /*
        STANDARD subscription: includes historical, probability, fin_math
        GENERIC trial licence from BO only for STANDARD
        COMPANY licence from BO only for STANDARD
    */
    public activeStandardSubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
    public activeUserLicenceDate$ = new BehaviorSubject<string | null>(null);
    public companyLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);

    /*
        PROPERTY FOR RENT subscription: includes rent_property
        GENERIC trial licence from BO only for PROPERTY FOR RENT
        COMPANY licence from BO only for PROPERTY FOR RENT
    */
    public activeRentPropertySubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
    public activeUserRentPropertyLicenceDate$ = new BehaviorSubject<string | null>(null);
    public companyRentPropertyLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);

    /*
        MORTGAGE AND INVEST subscription: includes rent_property
        GENERIC trial licence from BO only for MORTGAGE AND INVEST
        COMPANY licence from BO only for MORTGAGE AND INVEST
    */
    public activeMortgageInvestSubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
    public activeUserMortgageInvestLicenceDate$ = new BehaviorSubject<string | null>(null);
    public companyMortgageInvestLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);

    /*
        HISTORICAL subscription: includes rent_property
        GENERIC trial licence from BO only for HISTORICAL
        COMPANY licence from BO only for HISTORICAL
    */
    public activeHistoricalSubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
    public activeUserHistoricalLicenceDate$ = new BehaviorSubject<string | null>(null);
    public companyHistoricalLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);

    /*
        PROBABILITY subscription: includes rent_property
        GENERIC trial licence from BO only for PROBABILITY
        COMPANY licence from BO only for PROBABILITY
    */
    public activeProbabilitySubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
    public activeUserProbabilityLicenceDate$ = new BehaviorSubject<string | null>(null);
    public companyProbabilityLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);

    /*
        FINMATH subscription: includes rent_property
        GENERIC trial licence from BO only for FINMATH
        COMPANY licence from BO only for FINMATH
    */
    public activeFinMathSubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
    public activeUserFinMathLicenceDate$ = new BehaviorSubject<string | null>(null);
    public companyFinMathLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);

    /*
        CURRENTEVENTS subscription: includes rent_property
        GENERIC trial licence from BO only for CURRENTEVENTS
        COMPANY licence from BO only for CURRENTEVENTS
    */
    public activeCurrentEventsSubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
    public activeUserCurrentEventsLicenceDate$ = new BehaviorSubject<string | null>(null);
    public companyCurrentEventsLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);

    /* ADD PRODUCT GUIDE (step-1)
        public activeExampleSubscription$ = new BehaviorSubject<SubscriptionsResource | null | undefined>(undefined);
        public activeUserExampleLicenceDate$ = new BehaviorSubject<string | null>(null);
        public companyExampleLicence$ = new BehaviorSubject<CompanyLicenceResource | null>(null);
    */
    constructor(
        public dialog: MatDialog,
        private stripeApiService: StripeService,
        private sanitizer: DomSanitizer,
        private readonly userService: UserService,
    ) {
        if (!this.stripeLoaded) {
            const accToken =
                sessionStorage.getItem('accessToken') && sessionStorage.getItem('accessToken') !== 'undefined'
                    ? sessionStorage.getItem('accessToken')
                    : null;

            // Ošetření proti volání bez autentizace.
            if (accToken) {
                this.loadStripe();
            }
        }
    }

    get $products(): Observable<Product[]> {
        return this.products.asObservable();
    }

    public loadStripe(): void {
        loadStripe(environment.stripeKey).then((stripe: Stripe | null) => {
            if (stripe) {
                this.stripe = stripe;
                this.stripeApiService
                    .getAllListOfProducts()
                    .pipe(
                        catchError(() => of(null)),
                        switchMap((products: any) => {
                            if (products) {
                                this.products.next(products.products);
                            }

                            return this.userService.user$;
                        }),
                    )
                    .subscribe((user: UserResource | null) => {
                        if (user) {
                            this.getSubscriptions(user);
                        }
                        this.stripeLoaded = true;
                    });
            }
        });
    }

    getSubscriptions(user: UserResource): void {
        const standardUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();
        const historicalUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();
        const probabilityUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();
        const finMathUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();
        const rentPropertyUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();
        const mortgageInvUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();
        const currentEventsUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();

        /* ADD PRODUCT GUIDE (step-2)
        const exampleUserLicence: UserLicenceResource[] = new Array<UserLicenceResource>();
        */
        const standardCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();
        const historicalCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();
        const probabilityCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();
        const finMathCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();
        const rentPropertyCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();
        const mortgageInvCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();
        const currentEventsCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();

        if (user?.company?.licences && user.company.licences.length > 0) {
            /* ADD PRODUCT GUIDE (step-3)
                const exampleCompany: CompanyLicenceResource[] = new Array<CompanyLicenceResource>();
            */
            user?.company?.licences.forEach((licence: CompanyLicenceResource) => {
                const validFrom = moment(licence.validFrom, 'DD.MM.YYYY HH:mm');
                const validUntil = moment(licence.validUntil, 'DD.MM.YYYY HH:mm');
                if (
                    moment().isBetween(validFrom, validUntil) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.standard ||
                        licence.subscriptionName === SubscriptionsNamesProd.standard)
                ) {
                    standardCompany.push(licence);
                }

                if (
                    moment().isBetween(validFrom, validUntil) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.historical ||
                        licence.subscriptionName === SubscriptionsNamesProd.historical)
                ) {
                    historicalCompany.push(licence);
                }

                if (
                    moment().isBetween(validFrom, validUntil) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.probability ||
                        licence.subscriptionName === SubscriptionsNamesProd.probability)
                ) {
                    probabilityCompany.push(licence);
                }

                if (
                    moment().isBetween(validFrom, validUntil) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.finmath ||
                        licence.subscriptionName === SubscriptionsNamesProd.finmath)
                ) {
                    finMathCompany.push(licence);
                }

                if (
                    (moment().isBetween(validFrom, validUntil) && licence.subscriptionName === SubscriptionsNamesDev.estates) ||
                    licence.subscriptionName === SubscriptionsNamesProd.estates
                ) {
                    rentPropertyCompany.push(licence);
                }

                if (
                    (moment().isBetween(validFrom, validUntil) && licence.subscriptionName === SubscriptionsNamesDev.mortgage) ||
                    licence.subscriptionName === SubscriptionsNamesProd.mortgage
                ) {
                    mortgageInvCompany.push(licence);
                }

                if (
                    (moment().isBetween(validFrom, validUntil) && licence.subscriptionName === SubscriptionsNamesDev.currentEvents) ||
                    licence.subscriptionName === SubscriptionsNamesProd.currentEvents
                ) {
                    currentEventsCompany.push(licence);
                }

                /* ADD PRODUCT GUIDE (step-4)
                    if (moment().isBetween(validFrom, validUntil) && (licence.subscriptionName === 'Example DEV product name' || licence.subscriptionName === 'Example PROD product name')) {
                        exampleCompany.push(licence);
                    }
                */
            });

            //STANDARD company licence
            if (standardCompany.length > 0) {
                this.companyLicence$.next(standardCompany[0]);
                this.activeStandardSubscription$.next(null);
            } else {
                this.companyLicence$.next(null);
            }

            //HISTORICAL company licence
            if (historicalCompany.length > 0) {
                this.companyHistoricalLicence$.next(historicalCompany[0]);
                this.activeHistoricalSubscription$.next(null);
            } else {
                this.companyHistoricalLicence$.next(null);
            }

            //PROBABILITY company licence
            if (probabilityCompany.length > 0) {
                this.companyProbabilityLicence$.next(probabilityCompany[0]);
                this.activeProbabilitySubscription$.next(null);
            } else {
                this.companyProbabilityLicence$.next(null);
            }

            //FINMATH company licence
            if (finMathCompany.length > 0) {
                this.companyFinMathLicence$.next(finMathCompany[0]);
                this.activeFinMathSubscription$.next(null);
            } else {
                this.companyFinMathLicence$.next(null);
            }

            //PROPERTY company licence
            if (rentPropertyCompany.length > 0) {
                this.companyRentPropertyLicence$.next(rentPropertyCompany[0]);
                this.activeRentPropertySubscription$.next(null);
            } else {
                this.companyRentPropertyLicence$.next(null);
            }

            //MORTGAGE AND INVESTMENT company licence
            if (mortgageInvCompany.length > 0) {
                this.companyMortgageInvestLicence$.next(mortgageInvCompany[0]);
                this.activeMortgageInvestSubscription$.next(null);
            } else {
                this.companyMortgageInvestLicence$.next(null);
            }

            //CURRENT EVENTS company licence
            if (currentEventsCompany.length > 0) {
                this.companyCurrentEventsLicence$.next(currentEventsCompany[0]);
                this.activeCurrentEventsSubscription$.next(null);
            } else {
                this.companyCurrentEventsLicence$.next(null);
            }

            /* ADD PRODUCT GUIDE (step-5)
                if (exampleCompany.length > 0) {
                    this.companyExampleLicence$.next(exampleCompany[0]);
                    this.activeExampleSubscription$.next(null);
                } else {
                    this.companyExampleLicence$.next(null);
                }
            */
        }

        const standardActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();
        const historicalActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();
        const probabilityActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();
        const finMathActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();
        const rentPropertyActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();
        const mortgageInvActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();
        const currentEventsActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();

        /* ADD PRODUCT GUIDE (step-6)
            const exampleActive: SubscriptionsResource[] = new Array<SubscriptionsResource>();
        */
        user?.subscriptions?.forEach((subs: SubscriptionsResource) => {
            const currPeriodDate = moment(subs.currentPeriodEnd, 'DD.MM.YYYY HH:mm');
            const endsAtDate = moment(subs.endsAt, 'DD.MM.YYYY HH:mm');
            if (
                currPeriodDate.isAfter(moment()) &&
                (subs.name === SubscriptionsNamesDev.standard || subs.name === SubscriptionsNamesProd.standard)
            ) {
                if (subs.stripeStatus !== 'inactive') {
                    standardActive.push(subs);
                }
            }

            if (
                currPeriodDate.isAfter(moment()) &&
                (subs.name === SubscriptionsNamesDev.historical || subs.name === SubscriptionsNamesProd.historical)
            ) {
                if (subs.stripeStatus !== 'inactive') {
                    historicalActive.push(subs);
                }
            }

            if (
                currPeriodDate.isAfter(moment()) &&
                (subs.name === SubscriptionsNamesDev.probability || subs.name === SubscriptionsNamesProd.probability)
            ) {
                if (subs.stripeStatus !== 'inactive') {
                    probabilityActive.push(subs);
                }
            }

            if (
                currPeriodDate.isAfter(moment()) &&
                (subs.name === SubscriptionsNamesDev.finmath || subs.name === SubscriptionsNamesProd.finmath)
            ) {
                if (subs.stripeStatus !== 'inactive') {
                    finMathActive.push(subs);
                }
            }

            if (currPeriodDate.isAfter(moment()) && subs.name === SubscriptionsNamesDev.estates) {
                if (subs.stripeStatus !== 'inactive') {
                    rentPropertyActive.push(subs);
                }
            }
            if (currPeriodDate.isAfter(moment()) && subs.name === SubscriptionsNamesDev.mortgage) {
                if (subs.stripeStatus !== 'inactive') {
                    mortgageInvActive.push(subs);
                }
            }
            if (currPeriodDate.isAfter(moment()) && subs.name === SubscriptionsNamesDev.currentEvents) {
                if (subs.stripeStatus !== 'inactive') {
                    currentEventsActive.push(subs);
                }
            }

            /* ADD PRODUCT GUIDE (step-7)
                if (currPeriodDate.isAfter(moment()) && (subs.name === 'Example DEV product name' || subs.name === 'Example PROD product name')) {
                    if (subs.stripeStatus !== 'inactive') {
                        exampleActive.push(subs);
                    }
                }
            */
        });

        if (user?.licences?.length) {
            user.licences.forEach((licence: UserLicenceResource) => {
                const endsAt = moment(licence.trialEndsAt, 'DD.MM.YYYY HH:mm');
                if (
                    moment().isBefore(endsAt) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.standard ||
                        licence.subscriptionName === SubscriptionsNamesProd.standard)
                ) {
                    standardUserLicence.push(licence);
                }
                if (
                    moment().isBefore(endsAt) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.historical ||
                        licence.subscriptionName === SubscriptionsNamesProd.historical)
                ) {
                    historicalUserLicence.push(licence);
                }
                if (
                    moment().isBefore(endsAt) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.probability ||
                        licence.subscriptionName === SubscriptionsNamesProd.probability)
                ) {
                    probabilityUserLicence.push(licence);
                }
                if (
                    moment().isBefore(endsAt) &&
                    (licence.subscriptionName === SubscriptionsNamesDev.finmath ||
                        licence.subscriptionName === SubscriptionsNamesProd.finmath)
                ) {
                    finMathUserLicence.push(licence);
                }
                if (
                    (moment().isBefore(endsAt) && licence.subscriptionName === SubscriptionsNamesDev.estates) ||
                    licence.subscriptionName === SubscriptionsNamesProd.estates
                ) {
                    rentPropertyUserLicence.push(licence);
                }

                if (
                    (moment().isBefore(endsAt) && licence.subscriptionName === SubscriptionsNamesDev.mortgage) ||
                    licence.subscriptionName === SubscriptionsNamesDev.mortgage
                ) {
                    mortgageInvUserLicence.push(licence);
                }

                if (
                    (moment().isBefore(endsAt) && licence.subscriptionName === SubscriptionsNamesDev.currentEvents) ||
                    licence.subscriptionName === SubscriptionsNamesDev.currentEvents
                ) {
                    currentEventsUserLicence.push(licence);
                }

                /* ADD PRODUCT GUIDE (step-8)
                    if (moment().isBefore(endsAt) && (licence.subscriptionName === 'Example DEV product name' || licence.subscriptionName === 'Example PROD product name')) {
                        exampleUserLicence.push(licence);
                    }
                */
            });
        }

        if (!this.companyLicence$.value) {
            // STANDARD subscription
            if (standardActive.length > 0) {
                this.activeStandardSubscription$.next(standardActive[0]);
            } else {
                this.activeStandardSubscription$.next(null);
                if (standardUserLicence.length > 0) {
                    //GENERIC STANDARD subscription
                    this.activeUserLicenceDate$.next(standardUserLicence[0].trialEndsAt);
                } else {
                    this.activeUserLicenceDate$.next(null);
                }
            }
        }

        if (!this.companyHistoricalLicence$.value) {
            // HISTORICAL subscription
            if (historicalActive.length > 0) {
                this.activeHistoricalSubscription$.next(historicalActive[0]);
            } else {
                this.activeHistoricalSubscription$.next(null);
                if (historicalUserLicence.length > 0) {
                    //GENERIC HISTORICAL subscription
                    this.activeUserHistoricalLicenceDate$.next(historicalUserLicence[0].trialEndsAt);
                } else {
                    this.activeUserHistoricalLicenceDate$.next(null);
                }
            }
        }

        if (!this.companyProbabilityLicence$.value) {
            // PROBABILITY subscription
            if (probabilityActive.length > 0) {
                this.activeProbabilitySubscription$.next(probabilityActive[0]);
            } else {
                this.activeProbabilitySubscription$.next(null);
                if (probabilityUserLicence.length > 0) {
                    //GENERIC PROBABILITY subscription
                    this.activeUserProbabilityLicenceDate$.next(probabilityUserLicence[0].trialEndsAt);
                } else {
                    this.activeUserProbabilityLicenceDate$.next(null);
                }
            }
        }

        if (!this.companyFinMathLicence$.value) {
            // FINMATH subscription
            if (finMathActive.length > 0) {
                this.activeFinMathSubscription$.next(finMathActive[0]);
            } else {
                this.activeFinMathSubscription$.next(null);
                if (finMathUserLicence.length > 0) {
                    //GENERIC FINMATH subscription
                    this.activeUserFinMathLicenceDate$.next(finMathUserLicence[0].trialEndsAt);
                } else {
                    this.activeUserFinMathLicenceDate$.next(null);
                }
            }
        }

        if (!this.companyRentPropertyLicence$.value) {
            //RENT PROPERTY subcription
            if (rentPropertyActive.length > 0) {
                this.activeRentPropertySubscription$.next(rentPropertyActive[0]);
            } else {
                this.activeRentPropertySubscription$.next(null);
                if (rentPropertyUserLicence.length > 0) {
                    //GENERIC RENT PROPERTY subscription
                    this.activeUserRentPropertyLicenceDate$.next(rentPropertyUserLicence[0].trialEndsAt);
                } else {
                    this.activeUserRentPropertyLicenceDate$.next(null);
                }
            }
        }

        if (!this.companyMortgageInvestLicence$.value) {
            //RENT PROPERTY subcription
            if (mortgageInvActive.length > 0) {
                this.activeMortgageInvestSubscription$.next(mortgageInvActive[0]);
            } else {
                this.activeMortgageInvestSubscription$.next(null);
                if (mortgageInvUserLicence.length > 0) {
                    //GENERIC RENT PROPERTY subscription
                    this.activeUserMortgageInvestLicenceDate$.next(mortgageInvUserLicence[0].trialEndsAt);
                } else {
                    this.activeUserMortgageInvestLicenceDate$.next(null);
                }
            }
        }

        if (!this.companyCurrentEventsLicence$.value) {
            //CURRENT EVENTS subcription
            if (currentEventsActive.length > 0) {
                this.activeCurrentEventsSubscription$.next(currentEventsActive[0]);
            } else {
                this.activeCurrentEventsSubscription$.next(null);
                if (currentEventsUserLicence.length > 0) {
                    //GENERIC CURRENT EVENTS subscription
                    this.activeUserCurrentEventsLicenceDate$.next(currentEventsUserLicence[0].trialEndsAt);
                } else {
                    this.activeUserCurrentEventsLicenceDate$.next(null);
                }
            }
        }

        /* ADD PRODUCT GUIDE (step-9)
            if (!this.companyExampleLicence$.value) {
            //RENT PROPERTY subcription
            if (mortgageInvActive.length > 0) {
                this.activeMortgageInvestSubscription$.next(mortgageInvActive[0]);
                } else {
                    this.activeMortgageInvestSubscription$.next(null);
                    if (mortgageInvUserLicence.length > 0) {
                        //GENERIC RENT PROPERTY subscription
                        this.activeUserMortgageInvestLicenceDate$.next(mortgageInvUserLicence[0].trialEndsAt);
                    } else {
                        this.activeUserMortgageInvestLicenceDate$.next(null);
                    }
                }
            }
        */
        this.subscriptionsLoaded$.next(true);
        // this.checkUserAndCompanySubs(standardActive[0], standardCompany[0]);
        // this.checkUserAndCompanySubs(historicalActive[0], historicalCompany[0]);
        // this.checkUserAndCompanySubs(probabilityActive[0], probabilityCompany[0]);
        // this.checkUserAndCompanySubs(finMathActive[0], finMathCompany[0]);
        // this.checkUserAndCompanySubs(rentPropertyActive[0], rentPropertyCompany[0]);
        // this.checkUserAndCompanySubs(mortgageInvActive[0], mortgageInvCompany[0]);
    }

    checkUserAndCompanySubs(userSubs: SubscriptionsResource, companyLicence: CompanyLicenceResource): void {
        if (userSubs?.stripeStatus !== 'canceled' && companyLicence) {
            this.stripeApiService
                .cancelUserSubscription({ name: userSubs.name })
                .pipe(take(1))
                .subscribe((response) => {
                    console.info('Bylo zrušeno předplatné ' + userSubs.name + '! Z důvodu aktivní firemní licence.');
                });
        }
    }

    resetSubscriptions(): void {
        this.activeStandardSubscription$.next(undefined);
        this.activeUserLicenceDate$.next(null);
        this.companyLicence$.next(null);

        this.activeRentPropertySubscription$.next(undefined);
        this.activeUserRentPropertyLicenceDate$.next(null);
        this.companyRentPropertyLicence$.next(null);

        this.activeMortgageInvestSubscription$.next(undefined);
        this.activeUserMortgageInvestLicenceDate$.next(null);
        this.companyMortgageInvestLicence$.next(null);

        this.activeHistoricalSubscription$.next(undefined);
        this.activeUserHistoricalLicenceDate$.next(null);
        this.companyHistoricalLicence$.next(null);

        this.activeProbabilitySubscription$.next(undefined);
        this.activeUserProbabilityLicenceDate$.next(null);
        this.companyProbabilityLicence$.next(null);

        this.activeFinMathSubscription$.next(undefined);
        this.activeUserFinMathLicenceDate$.next(null);
        this.companyFinMathLicence$.next(null);

        this.activeCurrentEventsSubscription$.next(undefined);
        this.activeUserCurrentEventsLicenceDate$.next(null);
        this.companyCurrentEventsLicence$.next(null);

        /* ADD PRODUCT GUIDE (step-10)
            this.activeExampleSubscription$.next(undefined);
            this.activeUserExampleLicenceDate$.next(null);
            this.companyExampleLicence$.next(null);
        */
    }

    public hasUserAnySubsOrLicence(): Observable<boolean> {
        return this.activeStandardSubscription$.pipe(
            startWith(null),
            combineLatestWith(
                this.companyLicence$,
                this.activeUserLicenceDate$,
                this.activeRentPropertySubscription$,
                this.companyRentPropertyLicence$,
                this.activeUserRentPropertyLicenceDate$,
                this.activeMortgageInvestSubscription$,
                this.companyMortgageInvestLicence$,
                this.activeUserMortgageInvestLicenceDate$,
                this.activeHistoricalSubscription$,
                this.companyHistoricalLicence$,
                this.activeUserHistoricalLicenceDate$,
                this.activeProbabilitySubscription$,
                this.companyProbabilityLicence$,
                this.activeUserProbabilityLicenceDate$,
                this.activeFinMathSubscription$,
                this.companyFinMathLicence$,
                this.activeUserFinMathLicenceDate$,
                this.activeCurrentEventsSubscription$,
                this.activeUserCurrentEventsLicenceDate$,
                this.companyCurrentEventsLicence$,
                /* ADD PRODUCT GUIDE (step-14)
                this.activeExampleSubscription$,
                this.companyExampleLicence$,
                this.activeUserExampleLicenceDate$
                */
            ),
            /* ADD PRODUCT GUIDE (step-15)
                #add to map and then to condition
                exampleActive, exampleComp, exampleUser
                exampleActive || exampleComp || exampleUser
            */
            map(
                ([
                    standardActive,
                    standardComp,
                    standardUser,
                    rentActive,
                    rentComp,
                    rentUser,
                    mortActive,
                    mortComp,
                    mortUser,
                    historicalActive,
                    historicalComp,
                    historicalUser,
                    probabilityActive,
                    probabilityComp,
                    probabilityUser,
                    finMathActive,
                    finMathComp,
                    finMathUser,
                    currentEventsActive,
                    currentEventsUser,
                    currentEventsComp,
                ]) => {
                    if (
                        standardActive ||
                        standardComp ||
                        standardUser ||
                        rentActive ||
                        rentComp ||
                        rentUser ||
                        mortActive ||
                        mortComp ||
                        mortUser ||
                        historicalActive ||
                        historicalComp ||
                        historicalUser ||
                        probabilityActive ||
                        probabilityComp ||
                        probabilityUser ||
                        finMathActive ||
                        finMathComp ||
                        finMathUser ||
                        currentEventsActive ||
                        currentEventsUser ||
                        currentEventsComp
                    ) {
                        return true;
                    }
                    return false;
                },
            ),
        );
    }

    getStripeCardElement(): StripeCardElement {
        const stripeElementStyle: StripeElementStyle = {
            base: {
                iconColor: '#c4f0ff',
                color: '#111111',
                fontWeight: 400,
                fontSize: '1.2rem',
                fontSmoothing: 'antialiased',
                padding: '1rem',
            },
            invalid: {
                iconColor: '#E84E0E',
                color: '#E84E0E',
            },
        };
        if (this.stripe) {
            console.log(this.stripe);
            return this.stripe.elements().create('card', {
                style: stripeElementStyle,
                hidePostalCode: true,
            });
        }
        return new Object() as StripeCardElement;
    }

    processPaymentIntent(paymentIntent: any): Observable<KfpPaymentIntent> {
        if (paymentIntent.paymentIntent?.status === 'requires_action') {
            if (!paymentIntent.paymentIntent?.livemode) {
                return of({
                    threeDrequired: true,
                    threeDUrl: this.sanitizer.bypassSecurityTrustResourceUrl(
                        paymentIntent.paymentIntent?.next_action?.use_stripe_sdk.stripe_js,
                    ),
                });
            } else {
                return of({
                    threeDrequired: true,
                    threeDUrl: this.sanitizer.bypassSecurityTrustResourceUrl(
                        paymentIntent.paymentIntent?.next_action?.use_stripe_sdk.three_ds_method_url,
                    ),
                });
            }
        }
        return of({
            threeDrequired: false,
        });
    }

    getPaymentIntent(clientSecret: string): Observable<KfpPaymentIntent | null> {
        if (clientSecret && this.stripe) {
            const paymentIntentPromise = this.stripe.retrievePaymentIntent(clientSecret).then((paymentIntent: any) => {
                if (paymentIntent.paymentIntent?.status === 'requires_action') {
                    if (!paymentIntent.paymentIntent?.livemode) {
                        return {
                            threeDrequired: true,
                            threeDUrl: this.sanitizer.bypassSecurityTrustResourceUrl(
                                paymentIntent.paymentIntent?.next_action?.use_stripe_sdk.stripe_js,
                            ),
                        };
                    } else {
                        return {
                            threeDrequired: true,
                            threeDUrl: this.sanitizer.bypassSecurityTrustResourceUrl(
                                paymentIntent.paymentIntent?.next_action?.use_stripe_sdk.three_ds_method_url,
                            ),
                        };
                    }
                } else {
                    return {
                        threeDrequired: false,
                    };
                }
            });

            if (paymentIntentPromise) {
                return from(paymentIntentPromise);
            }
        }
        return of(null);
    }

    createPayment(stripeElements: StripeCardElement): Observable<KfpCreatePayment | null> {
        const paymentMethod = this.stripe?.createPaymentMethod({ type: 'card', card: stripeElements }).then((result) => {
            if (result.paymentMethod) {
                if (result.paymentMethod.card?.three_d_secure_usage && result.paymentMethod.card.three_d_secure_usage.supported) {
                    return { paymentMethod: result.paymentMethod, needThreeDSecure: true };
                } else {
                    return { paymentMethod: result.paymentMethod, needThreeDSecure: false };
                }
            }
            return null;
        });
        if (paymentMethod) {
            return from(paymentMethod);
        }

        return of(null);
    }

    public openPaymentDialog(method: string, coupon: any, product: any): MatDialogRef<StripeCardComponent, any> | null {
        if (method === 'card') {
            return this.dialog.open(StripeCardComponent, {
                data: {
                    name: method,
                    coupon: coupon,
                    product: product,
                },
                // width: '40%',
                height: 'auto',
                autoFocus: true,
            });
        }

        // if (method === 'bank') {
        //     return this.dialog.open(StripeCardComponent, {
        //         //BankaccountComponent
        //         data: {
        //             name: method,
        //         },
        //         width: '35%',
        //         maxHeight: '350px',
        //         height: 'auto',
        //         autoFocus: true,
        //     });
        // }

        return null;
    }
}
